const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/app/investment/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
    BASE_URL : "https://one-gram-gold-56653df92ed6.herokuapp.com",
    // BASE_URL : "http://localhost:5001",
    // BASE_URL : "http://13.60.50.77:5001"
}

export default appConfig
