import ApiService from './ApiService'
import appConfig from 'configs/app.config'

const { BASE_URL } = appConfig


export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/users/signin`,
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/users`,
        method: 'post',
        data,
    })
}

export async function apiSignUpSimple(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/users/createSimpleUser`,
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: `https://onemg-gold-c666fbeca6bf.herokuapp.com/api/v1/users/resetPassword`,
        // url: `http://192.168.50.39:5002/api/v1/users/resetPassword`,
        method: 'post',
        data,
    })
}

export async function apiOtp(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/users/${data?.userId}/verifyEmailOTP`,
        method: 'post',
       data,
    })
}

export async function apiVerifyEmailandId(data){
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/users/verifyEmailAndId`,
        method: 'post',
        data,
    })
}

export async function apiGetS3Url(data){
    return ApiService.fetchData({
        url:  `${BASE_URL}/api/v1/users/s3Url`,
        method: 'post',
        data,
        headers: {  'Content-Type': 'multipart/form-data',}
    })
}


export async function apiGetGoldenUser(id) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/goldenUsers/${id}`,
        method: 'get',
    })
}