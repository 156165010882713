import ApiService from './ApiService'
import appConfig from 'configs/app.config'

const { BASE_URL } = appConfig

export async function apiGetInvestmentDashboardData(data) {
    
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/investments/user/${data}/summary`,
        method: 'get',
    })
} 

export async function apiGetInvestmentsData(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/investments/user/${data}`,
        method: 'get',
    })
} 

export async function apiGetInvestmentSlipData(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/transactionSlip/${data}`,
        method: 'get',
    })
} 


export async function apiAddInvestment(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/investments/lock`,
        method: 'post',
        data,
    })
}

export async function apiUploadPaymentSlip(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/transactionSlip/upload`,
        method: 'post',
        data,
        headers: {  'Content-Type': 'multipart/form-data',}
    })
}




export async function apiGetUserReceipts(userId) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/transactionSlip/${userId}`,
        method: 'get',
    })
}

export async function apiGetUserByReferrelCode(referrelCode) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/sendMoney/${referrelCode}`,
        method: 'get',
    })
}

export async function apiTransferAmountToUser(data) {
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/sendMoney`,
        method: 'post',
        data,
    })
}

export async function apiRedeemAmount(data){
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/redeem`,
        method: 'post',
        data,
    })
}

export async function apiReinvestRewards(data){
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/transactionSlip/reinvestRewards`,
        method: 'post',
        data,
    })
}

export async function apiGetTransactionsData(userId){
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/users/${userId}/getUserTransactions`,
        method: 'get',
    })
}

export async function apiGetSingleUser(userId){
    return ApiService.fetchData({
        url: `${BASE_URL}/api/v1/users/${userId}`,
        method: 'get',
    })
}
