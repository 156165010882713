import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiGetS3Url, apiOtp, apiSignIn, apiSignOut, apiSignUp, apiSignUpSimple, apiVerifyEmailandId } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { apiUploadPaymentSlip } from 'services/InvestmentService'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
           
            if (resp.data.data) {
                const { token } = resp.data.data
                dispatch(onSignInSuccess(token))
                if (resp.data.data.user) {
                    dispatch(
                        setUser(
                            {...resp.data.data.user, userName : resp.data.data.user.firstName + ' ' +resp.data.data.user.lastName,authority:resp.data.data.user.referralCode === 'RBC-SN8QN'  || resp.data.data.user.referralCode === 'RBC-0000-0001' ? ['admin', 'ADMIN'] : ['user']}|| {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const verifyEmailandId = async (values) => {
        try {
            const resp = await apiVerifyEmailandId(values)
            if (resp.data.status === 'success') {
               
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data.data) {
              
                    dispatch(
                        setUser(
                            {...resp.data.data.user, userName : resp.data.data.user.firstName + ' ' +resp.data.data.user.lastName,authority:resp.data.data.user.referralCode === 'RBC-SN8QN' ? ['admin', 'ADMIN'] : ['user']}|| {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const onSignUpSimple = async (values) => {
        try {
            const resp = await apiSignUpSimple(values)
            if (resp.data.data) {
              
                navigate(
                    '/otp',{ state: { user: resp.data.data.user } }
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const s3Url = async (formData) => {
        try {
            const resp = await apiGetS3Url(formData)
            if (resp.data.status === 'success') {
               
                return {
                    status: 'success',
                    url: resp.data.data,
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const uploadPaymentSlip = async (formData) => {
        try {
            const resp = await apiUploadPaymentSlip(formData)
            if (resp.data.status ==='success') {
               
                return {
                    status: 'success',
                    message: resp.data.data,
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }


    const otp = async (values) => {
        try {
            const resp = await apiOtp(values)

            if (resp.data.data) {
                const { token } = resp.data.data
                dispatch(onSignInSuccess(token))
                if (resp.data.data.user) {
                  
                    dispatch(
                        setUser(
                            {...resp.data.data.user, userName : resp.data.data.user.firstName + ' ' +resp.data.data.user.lastName,authority:resp.data.data.user.rederralCode === 'RBC-SN8QN' ? ['admin','ADMIN'] : ['user']}|| {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }

           
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        otp,
        verifyEmailandId,
        s3Url,
        uploadPaymentSlip,
        onSignUpSimple,
    }
}

export default useAuth
